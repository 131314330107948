import React from 'react'
// import Container from '../ui/Container'
import { Col, Container, Image, Row } from 'react-bootstrap'
import CustomImg from "../Assets/img/custom-design.png"
import Heading from '../ui/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const CustomDesign = () => {
  return (
    <section className='custom-design section-space'>
        <Container fluid className='showcase-container'>
            <Row className='align-items-center'>
                <Col md={6} xs={12} className='text-center'>
                    <Image src={CustomImg} alt='Custom Design Jewellery ' />
                </Col>

                <Col md={6} xs={12}>
                    <Heading>
                        <span className='text-uppercase'>Be the Designer</span>
                        <h2 className='text-uppercase'>Custom Design Jewellery </h2>
                        <p>Indulge in the luxury of self-expression with our custom design Jewellery service. From intricate details to personalized touches, each piece is crafted to reflect your unique style and story. Elevate your look with bespoke treasures that are as exceptional as you are.</p>
                    </Heading>

                    <div className='text-center'>
                        <Link to='/custom-design' className="btn outline-base-btn large ">Get Started</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default CustomDesign